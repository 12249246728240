import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Top VueJS Development Company`,
    content:
      "As a leading VueJS Development Company, we create pixel-perfect bespoke UI and adapt them for optimal performance.",
  };

  const commonContent6 = {
    title: "VueJS Development Services",
    para: "Octet is your one-stop Vue.js development company, providing end-to-end services to create lightweight, high-performing, and result-driven VueJS apps that contribute to your company's growth.",
    img: ["what-service-image-vuejs.webp"],
    imageTitle: "VueJs Development Company",
    imageAlt: "VueJs development services",
    content: [
      {
        title: "Custom App Development",
        text: "We create custom VueJs applications to match your specific company needs and goals.",
      },
      {
        title: "Mobile App Development",
        text: "We create high-performance, functional, interactive mobile apps to engage your target audience.",
      },
      {
        title: "Web Application Development",
        text: "Our knowledge of VueJs development services allows us to construct responsive, scalable, and feature-rich web apps.",
      },
      {
        title: "Third-Party Integration",
        text: "We easily integrate third-party services into your projects to enhance application functionality.",
      },
      {
        title: "Interactive UI Development",
        text: "With our unique VueJs services, we improve the user interface and offer an engaging and intuitive experience.",
      },
      {
        title: "Portal Development Solutions",
        text: "We provide complete portal Vuejs development services and create sturdy and dynamic online platforms.",
      },
      {
        title: "Real-Time Application",
        text: "Our Vue.js development agency offers real-time solutions that utilize our VueJs knowledge to create dynamic and interactive user experiences.",
      },
      {
        title: "Support & Maintenance",
        text: "Our Vue.js development company offers continuous support and maintenance to ensure your web and mobile applications work smoothly..",
      },
    ],
  };

  const commonContent = {
    title: "Transforming Industries: VueJs Development Agency",
    para: "VueJs is a versatile platform that improves the interface for various business types. Our Vue.js development company constructs dynamic websites and redesigns old ones for all industries, ranging from startups to established corporations.",
    img: ["who-needs-vuejs.webp"],
    imageTitle: "VueJs Development Services",
    imageAlt: "VueJs development company",
    content: [
      {
        title: "E-commerce",
        text: "Companies seek to create dynamic and highly functional websites to give seamless website experiences to their customers.",
      },
      {
        title: "SMBs",
        text: "Companies are looking to boost their online presence using low-cost alternatives for designing websites based on consumer requirements.",
      },
      {
        title: "Startups",
        text: "Companies who want to accelerate their business growth by leveraging the latest tools and technologies offered by VueJs.",
      },
      {
        title: "Enterprises",
        text: "Companies that wish to simplify their complex platform while providing their clients a high-performing, reliable platform for a better experience.",
      },
    ],
  };

  const commonContent2 = {
    title: "Strategic Benefits Of VueJs Development Companies",
    para: "VueJs Development Services enables organizations to construct powerful interfaces using its syntax and complete tooling to create highly functional, responsive, and intuitive applications.",
    img: ["what-are-benefits-vuejs-services.webp"],
    imageTitle: "Benefits of VueJs Development ",
    imageAlt: "vuejs development services",
    content: [
      {
        title: "Increased Efficiency",
        text: "VueJs Development Services provides reusable components and streamlines workflows, reducing development time and increasing productivity.",
      },
      {
        title: "Scalability",
        text: "Vue.js development agency provides scalable solutions for your business, keeping your website updated with the latest trends while ensuring performance.",
      },
      {
        title: "Extensive Ecosystem",
        text: "Vue.js development companies offer an innovative solution for your business through its extensive plugins, community-driven tools, and libraries.",
      },
      {
        title: "Enhanced User Experience",
        text: "VueJs Development Service assists in creating visually interactive, functional and responsive websites that results in enhanced user experience.",
      },
    ],
  };
  const commonContent3 = {
    title: "Partner with the Best Vue.js Development Company",
    linkText: ``,
    para: "Octet is a leading Vue.js Development Agency that creates innovative interfaces using our significant industry knowledge. We use our expertise in VueJs Development Solutions to develop customized and robust online solutions for your company..",
    img: ["why-opt-for-vuejs.webp"],
    imageTitle: "vuejs development company in india",
    imageAlt: "vuejs development company",
    content: [
      {
        title: "Expertise and Experience",
        text: "We have appropriate expertise and experience in providing VueJs Development Services and better than expected results.",
      },
      {
        title: "Customized Solutions",
        text: "We take a personalized approach to each project and provide tailored VueJS solutions depending on your business objectives.",
      },
      {
        title: "Dedicated Team",
        text: "We provide a specialized team for your VueJs solutions, who will turn your ideas into reality and ensure timely delivery.",
      },
      {
        title: "Timely Delivery",
        text: "We streamline the development process to ensure your projects run smoothly and on time.",
      },
    ],
  };
  const commonContent4 = {
    title: "Building Brands with our Vue.js Development Agency",
    para: "We provide complete and personalized VueJs Development services based on your project requirements, ensuring seamless integration and business success.",
    img: ["how-we-conduct-vuejs-services.webp"],
    imageTitle: "VueJs Development Process",
    imageAlt: "vuejs development company in india",
    content: [
      {
        title: "1. Define UX & Flows",
        text: "We initially identify the UX characteristics and user processes to determine the design structure.",
      },
      {
        title: "3. Create HTML Templates",
        text: "We transform the UI design into HTML templates, which are then translated into VueJS pages.",
      },
      {
        title: "2. Create UI Designs",
        text: "We create designs while considering brand requirements, typographic design principles, color theory, and UX rules.",
      },
      {
        title: "4. Convert to VueJs Pages",
        text: "Finally, we transfer the HTML code to the VueJS framework for easy comprehension and faster deployment.",
      },
    ],
  };
  const commonContent5 = {
    title: "Outcomes Of Investing In VueJs Development Company",
    para: "VueJs is a progressive framework for developing dynamic and interactive interfaces. Investing in our Vue.js Development Agency enables your company to create cost-effective, high-quality websites.",
    content: [
      {
        title: "30% Less Development Time",
        text: "VueJs' simplicity and reactivity can help accelerate development cycles, lowering development time and other resource expenditures.",
      },
      {
        title: "20% Code Reusability",
        text: "The VueJs framework's component-based architecture promotes code reuse and maintainability, potentially increasing returns.",
      },
      {
        title: "10% Increased Productivity",
        text: "Vue.js's excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      },
      {
        title: "10% Improved Performance",
        text: "Vue.js's excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them.",
  };
  const cta = {
    title: "Develop High-Quality Apps with VueJS Development",
  };
  const cta2 = {
    title: "Build Robust Apps with our VueJs Development",
  };
  const cta3 = {
    title: "Go Big by developing your App in VueJS!",
  };
  const cta4 = {
    title: "Unlock the Power of VueJS App Development",
  };
  const cta5 = {
    title: "Let’s Develop Something Extraordinary",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "LikeHire",
      desc: "A Healthcare Job Portal Platform",
      Industry: "HealthTech",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "VueJs Development",
          link: "/vuejs-development/",
        },
      ],
      link: "/project/ui-ux-design-likehire/",
      images: ["like-hire.webp", "like-hire-2.webp"],
      firstAlt: "outsource vuejs development",
      firstTitle: "Landing Page",
      secondAlt: "vue.js development agency",
      secondTitle: "Applicant's Screen",
    },
    {
      title: "Gizmo",
      desc: "A Market Research Platform",
      Industry: "Enterprise",
      WhatWeDid: [
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Rapid Prototyping",
          link: "/rapid-prototyping/",
        },
        {
          title: "Data Visualisation",
          link: "/data-visualisation/",
        },
      ],
      link: "/project/gizmo/",
      images: ["gizmo.webp", "gizmo-2.webp"],
      firstAlt: "vuejs development service",
      firstTitle: "Survey Dashboard",
      secondAlt: "vuejs development service",
      secondTitle: "Design Components",
    },
  ];

  const Questions = [
    "Can you customize VueJs Development Services?",
    "Are your VueJs Services suitable for rapid prototyping?",
    "Can you integrate VueJs into existing web applications?",
    "Does VueJs work alongside JavaScript libraries and frameworks?",
    "How does your Vue.js Development Agency improve user experience?",
    "What is the duration and cost of your VueJs Development Services?",
  ];
  const clientsTitle = "Trusted By Clients Worldwide";
  const faqDes = `We understand the questions you may have concerning our VueJS Development Services. In this area, we share additional information on our Vue.Js Development Company. However, if you still have any questions, please <a href="/contact-us/">contact us</a>.`;

  const faqData = [
    {
      para: [
        `Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands. `,
        `VueJs is a flexible and versatile JavaScript Framework that we use for customization and offers robust web solutions.`,
        `To offer a tailor-made VueJs Development service and enhance your user experience, we understand your business objectives, target audience, and specific project requirements.`,
      ],
    },
    {
      para: [
        `Yes, VueJs is suitable for <a target="_blank" rel="noreferrer" href="/rapid-prototyping/">Rapid Prototyping</a> because of its ease of use. It is an efficient model as its data binding and components-based architecture allow rapid prototyping building and iterations. `,
        `VueJs also offers pre-built UI components and libraries that accelerate the prototyping process by gathering feedback on the ideas, refining designs, and delivering high-quality solutions.`,
      ],
    },
    {
      para: [
        `Yes, we can seamlessly integrate our VueJs Development Services into existing applications because of the platform's versatility and flexibility.`,
        `We consider VueJs to be the best option for enhancing the functionality of your application.`,
        `It assists in improving your business's performance, enhances user experience, and offers easily scalable options.`,
      ],
    },
    {
      para: [
        `VueJs is a flexible platform that seamlessly integrates other javascript libraries and frameworks.`,
        `It also provides plugins that integrate third-party libraries and offer direct integration with <a target="_blank" rel="noreferrer" href="/reactjs-development/">React Js</a> and <a target="_blank" rel="noreferrer" href="/angularjs-development/">Angular Js</a>. `,
        `Vue Js is the most versatile platform to enhance your existing applications by leveraging the latest tools and technologies.`,
      ],
    },
    {
      para: [
        `Our VueJs Development Service enables us to provide highly creative and responsive user interfaces based on your business requirements.`,
        `Through VueJs, we develop dynamic websites and update them using the latest technologies to create an engaging interface.`,
        `With our flexible approach and Vuex library, we offer optimized websites, improved usability, and an intuitive user experience.`,
      ],
    },
    {
      para: [
        `The duration and cost of our VueJs Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you contact us for an accurate timeline and detailed quotation.`,
      ],
    },
  ];

  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Can you customize VueJs Development Services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.
          VueJs is a flexible and versatile JavaScript Framework that we use for customization and offers robust web solutions.
          To offer a tailor-made VueJs Development service and enhance your user experience, we understand your business objectives, target audience, and specific project requirements."
              }
            },{
              "@type": "Question",
              "name": "Are your VueJs Services suitable for rapid prototyping?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, VueJs is suitable for Rapid Prototyping because of its ease of use. It is an efficient model as its data binding and components-based architecture allow rapid prototyping building and iterations.
          VueJs also offers pre-built UI components and libraries that accelerate the prototyping process by gathering feedback on the ideas, refining designs, and delivering high-quality solutions."
              }
            },{
              "@type": "Question",
              "name": "Can you integrate VueJs into existing web applications?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we can seamlessly integrate our VueJs Development Services into existing applications because of the platform's versatility and flexibility.
          We consider VueJs to be the best option for enhancing the functionality of your application.
          It assists in improving your business's performance, enhances user experience, and offers easily scalable options."
              }
            },{
              "@type": "Question",
              "name": "Does VueJs work alongside JavaScript libraries and frameworks?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "VueJs is a flexible platform that seamlessly integrates other javascript libraries and frameworks.
          It also provides plugins that integrate third-party libraries and offer direct integration with ReactJs and AngularJs.
          Vue Js is the most versatile platform to enhance your existing applications by leveraging the latest tools and technologies."
              }
            },{
              "@type": "Question",
              "name": "How does your Vue.js Development Agency improve user experience?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our VueJs Development Service enables us to provide highly creative and responsive user interfaces based on your business requirements.
          Through VueJs, we develop dynamic websites and update them using the latest technologies to create an engaging interface.
          With our flexible approach and Vuex library, we offer optimized websites, improved usability, and an intuitive user experience."
              }
            },{
              "@type": "Question",
              "name": "What is the duration and cost of your VueJs Development Services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The duration and cost of our VueJs Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you contact us for an accurate timeline and detailed quotation."
              }
            }]
          }
          
        `}
      </script>
    </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta2} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta3} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                Our Successful VueJS <span class='h1-span'>Development Projects</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials
            title={"Client’s Take On Us"}
          />
          <Cta data={cta4} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta5} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="Top VueJS Development Company"
    description="Drive innovation in your web and mobile apps with our Vuejs development company. Our Vuejs development services enhance user interactions and conversions. "
  />
);
